<template>
    <div class="TrackTracePop_wrapper">
        <header class="TTheader">
            <h2>International Shipment Tracking</h2>
            <i class="closeme" @click="closeModal">X</i>
        </header>
        <div class="sub_head">
            Shipment Tracking Status - {{ airwayBill }}
        </div>
        <div class="TT_table_wrapper">
            <table v-if="!loading && (trackingList && trackingList.length > 0)">
                <tr>
                    <td>EVENT CODE</td>
                    <td>DATE & TIME</td>
                    <td>RECEIVER NAME</td>
                    <td>COMMENT</td>
                </tr>
                <tbody>
                    <tr v-for="(item, i) in trackingList" :key="i">
                        <td>{{ item.eventCode }}</td>
                        <td>{{ item.timestamp }}</td>
                        <td>{{ item.receiverName }}</td>
                        <td>{{ item.comment }}</td>
                    </tr>
                </tbody>
            </table>
            <div v-if="!loading && (trackingList && trackingList.length == 0)">
                <p style="text-align: center;">There are no shipment tracking history to show yet.</p>
            </div>

            <div style="height:100px;" v-if="loading">
                <loader />
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    name: "InternationalShipmentTrackingPopup",
    data() {
        return {
            loading: true,
            trackingList: [],
        };
    },
    async created() {
        this.loading = true;
        await this.getInternationalShipmentTracking(this.bookingId);
    },
    methods: {
        ...mapActions({
			setInternationalShipmentTrackingList: 'internationShipmentTracking/setTrackingList',
		}),
        closeModal() {
            this.$emit("closeModal");
        },
        async getInternationalShipmentTracking(bookingId) {
            try {
                let trackingList = await this.setInternationalShipmentTrackingList(bookingId);
                console.log(trackingList[0]);
                this.trackingList = trackingList[0];
                this.loading = false;
            }
            catch (e) {
                console.log(e);
            }
        },
    },
    props: ["airwayBill", "bookingId"],
};
</script>
<style lang="scss">
$theme-color: #06a5ed;

.TrackTracePop_wrapper {
    padding: 0;

    .TTheader {
        height: 80px;
        background: $theme-color;
        text-align: center;

        h2 {
            display: inline-block;
            padding: 20px 0 5px 0;
            border-bottom: 2px solid #000;
        }

        i.closeme {
            right: 20px;
            top: 20px;
        }
    }

    .sub_head {
        background: #fff;
        color: #000;
        font-size: 20px;
        height: 40px;
        line-height: 40px;
        text-align: left;
        padding: 0 20px;
    }

    .TT_table_wrapper {
        padding: 0 10px;

        table {
            width: 100%;
            border: 1px solid #ddd;
            border-right: 0;
            border-bottom: 0;
            border-collapse: collapse;
            background: #fff;

            tr {
                td {
                    padding: 10px 5px;
                    border: 1px solid #ddd;
                    border-left: 0;
                    border-top: 0;
                }

                &:first-child td {
                    background: #eee;
                    color: #000;
                    font-weight: bold;
                    border-bottom-color: #ccc;
                    border-bottom-width: 2px;
                }
            }
        }
    }
}
</style>
<style scoped>
.TrackTracePop_wrapper .TT_table_wrapper table tbody tr:first-child td {
    font-weight: 400;
}
</style>
